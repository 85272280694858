import PropTypes from 'prop-types';
import ColumnsWrapper from 'libs/storyblok/content/Modules/ColumnsWrapper';
import { columnsHeadingFontKeys } from 'config/branding/columns-header-font-keys';
import CmsDataTransformerBlock from '@activebrands/core-web/libs/storyblok/content/CmsDataTransformerBlock';
import { transformButton } from '@activebrands/core-web/libs/storyblok/utils/transform-buttons';
import transformHeading from '@activebrands/core-web/libs/storyblok/utils/transform-heading';
import { useStyletron } from '@activebrands/core-web/libs/styletron';
import getContentWrapperStyles from '@activebrands/core-web/utils/style-functions/get-content-wrapper-styles';
import TopPaginationSlider from 'components/Header/Navigation/TopPaginationSlider';

const ThreeColumnsModule = ({ content = {}, isGrid = false }) => {
    const [css] = useStyletron();
    const { button = {}, columns = [], heading = [], headingStyle = 'md' } = content;

    const headingSettings = transformHeading({
        type: heading?.[0]?.type,
        defaultType: 'h3',
    });

    const transformedButton = button?.[0] ? transformButton(button?.[0]) : {};

    return (
        <section
            className={css({ marginBottom: isGrid ? 0 : 'var(--margin-bottom-module)', ...getContentWrapperStyles() })}
        >
            <TopPaginationSlider
                isDraggable
                $paginationStyle={{ display: 'none' }}
                button={transformedButton}
                buttonIconStyling={{ display: 'none' }}
                heading={heading?.length > 0 && heading?.[0]?.text}
                headingFontKeys={columnsHeadingFontKeys[headingStyle]}
                headingType={headingSettings.type}
                slidesPerView={[1.5, null, 2.5, null, 3]}
                spaceBetween="4px"
            >
                {columns.map((column, i) => (
                    <ColumnsWrapper
                        $style={{ display: 'flex', alignItems: 'center' }}
                        component={column.component}
                        key={column._uid}
                        numberOfItems={columns.length}
                        position={i}
                    >
                        <CmsDataTransformerBlock isThreeColumns block={column} />
                    </ColumnsWrapper>
                ))}
            </TopPaginationSlider>
        </section>
    );
};

ThreeColumnsModule.propTypes = {
    content: PropTypes.object,
    isGrid: PropTypes.bool,
};

export default ThreeColumnsModule;
